<template>
    <div>
        <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="600px">
            <el-form ref="form" label-position="left" label-width="120px">
                <el-form-item :label="label1" prop="expireTime">
                    <el-input v-model="value1" :maxlength="maxlength" placeholder="请输入" ref="input" v-if="!valueType1"></el-input>
                    <el-radio-group v-model="value1" v-if="valueType1 == 'radio'">
                        <el-radio :label="item.value" v-for="(item,index) in valueDic1" :key="index">{{ item.text }}</el-radio>
                    </el-radio-group>
                    <el-select v-model="value1" placeholder="请选择" clearable filterable v-if="valueType1 == 'select'">
                        <el-option v-for="(item,index) in valueDic1"
                            :key="index"
                            :label="item.text"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="label2" prop="expireTime" v-if="label2">
                    <el-input v-model="value2" :maxlength="maxlength" placeholder="请输入"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                value1: '',
                value2: '',
                inputType: 'string',
                maxlength: 20,
                title: '',
                label1: '',
                label2: '',
                valueType1: '',
                valueDic1: [],
            };
        },
        watch:{
            dialogVisible (val) {
                if(!val){
                    this.value1 = ''
                    this.value2 = ''
                    this.inputType = 'string'
                    this.maxlength = 20
                    this.title = ''
                    this.label1 = ''
                    this.label2 = ''
                    this.valueType1 = ''
                    this.valueDic1 = []
                }else{
                    this.$nextTick(()=>{
                        if(this.$refs['input']) this.$refs['input'].focus()
                    })
                }
            }
        },
        directives: {
            // 注册一个局部的自定义指令 v-focus
            focus: {
                // 指令的定义
                inserted: function (el) {
                    // 聚焦元素
                    el.querySelector('input').focus()
                }
            }
        },
        methods:{
            submit () {
                if(!this.value1){
                    this.$message.error('请填写'+this.label1)
                    return
                }
                if(this.title == '修改促销价/划线价' && !this.value2){
                    this.$message.error('请填写'+this.label2)
                    return
                }
                this.dialogVisible = false
                this.$emit('submitVal',{
                    value1: this.inputType=='money'?Number((this.value1*100).toFixed(0)):this.value1,
                    value2: this.inputType=='money'?Number((this.value2*100).toFixed(0)):this.value2,
                })
            },
            cancel () {
                this.$emit('closeDialog')
            }
        }
    }
</script>