<template>
    <div>
        <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="600px">
            <el-form ref="form" label-position="left" label-width="120px">
                <el-form-item label="支付方式" prop="payWay">
                    <div class="a-flex-rfsc">
                        <el-radio-group v-model="form.payWay" style="height: 32px" class="a-flex-rfsc">
                            <el-radio :disabled="(form.isTempCard != 1 && item.value == 3) || item.value == 2" :label="item.value" v-for="(item,index) in payWayDic" :key="index">{{ item.text }}</el-radio>
                        </el-radio-group>
                        <span style="margin: 0 10px" v-if="form.payWay == 3">，费用上限</span>
                        <jd-input-price v-model="form.maxPrice"  v-if="form.payWay == 3"></jd-input-price>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                title: '修改支付方式',
                form: {
                    payWay: '1',
                    maxPrice: ''
                },
                payWayDic: [],// 支付类型字典
            };
        },
        watch:{
            dialogVisible (val) {
                if(!val){
                    this.form.payWay = '1'
                    this.form.maxPrice = ''
                }
            }
        },
        mounted () {
            this.$getDic('payWay').then(res=>{ this.payWayDic = res; })
        },
        methods:{
            submit () {
                if(!this.form.maxPrice && this.form.payWay == 3){
                    this.$message.error('请填写临停后付费金额上限')
                    return
                }
                this.dialogVisible = false
                this.$emit('submitVal',{
                    payWay:  this.form.payWay,
                    maxPrice: Number((this.form.payWay*100).toFixed(0)),
                })
            },
            cancel () {
                this.$emit('closeDialog')
            }
        }
    }
</script>