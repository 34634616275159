<template>
    <div class="app-body monthCard">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">月卡批量添加</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="submit()">生成套餐</el-button>
            </div>
        </div>
        <el-card class="el-main sceneCheckContent">
            <div class="a-flex-rfsc a-flex-wrap">
                <span class="s-search-label a-flex-rfec" style="line-height: 32px;margin-right:20px">模板选择</span>
                <div v-for="(item) in groupIdList" 
                    :key="item.value" 
                    class="a-flex-ccc my-btn "
                    :class="item.value == groupId ? 'my-btn-active' : ''" 
                    @click="handlerChangeGroupId(item.value)">
                    <span class="a-fs-14 a-c-333">{{ item.label }}</span>
                    <img v-if="item.value == groupId" src="../../assets/icon/option-btn-check.png"
                        style="width: 20px;height: 14px; position: absolute; bottom: -1px; right: -1px;" />
                </div>
            </div>
            <div class="a-flex-rfsc">
                <!-- <le-select-remote-search label="模板" v-model="groupId" :options="optionsTemplateList" placeholder="请选择模版(可搜索)" /> -->
                <le-select-remote-search label="项目" v-model="incomeDistriId" :options="optionsincomeDistri" placeholder="请选择项目(可搜索)" />
                <le-select-remote-search 
                    label="站点"
                    :multiple="true" 
                    v-model="statioinIdList" 
                    :options="optionsStatioinIdList" 
                    placeholder="请选择站点(可搜索)" />
                <div class="a-flex-1 a-flex-rfec">
                    <el-button type="primary" @click="getTableData()">刷新模板</el-button>
                </div>
            </div>
            <el-table 
                ref="monthCardbatchlist" 
                @row-dblclick="dbclick"
                :cell-class-name="tableCellClassName"
                :row-style="rowStyle"
                :data="tableData" 
                :border="true"
                :span-method='mergeCols'
                style="width: 100%;" 
                height="500">
                <el-table-column prop="name" label="名称" min-width="140" fixed="left">
                    <template slot-scope="scope">
                        <span class="point-edit" :class="scope.row.name_edit?'a-c-red':'a-c-blue'">{{ scope.row.name?scope.row.name:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="promotionPrice" label="促销价/划线价" min-width="160">
                    <template slot-scope="scope">
                        <span class="point-edit" :class="scope.row.promotionPrice_edit?'a-c-red':'a-c-blue'">￥{{ util.numFormat(scope.row.promotionPrice) }}/￥{{ util.numFormat(scope.row.price) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="validity" label="有效天数(天)" min-width="100">
                    <template slot-scope="{ row }">
                        <span class="point-edit" :class="row.validity_edit?'a-c-red':'a-c-blue'">{{ row.validity?row.validity:'-' }}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="cardGroupName" label="卡组" min-width="120">
                    <template slot-scope="{ row }">
                        <span class="point-edit" :class="row.cardGroupName_edit?'a-c-red':'a-c-blue'">{{ row.cardGroupName?row.cardGroupName:'-' }}</span>
                    </template>
                </el-table-column> -->
                <el-table-column prop="cardPrice" label="实体卡费用" min-width="100">
                    <template slot-scope="{ row }">
                        <span class="point-edit" :class="row.cardPrice_edit?'a-c-red':'a-c-blue'">￥{{ util.numFormat(row.cardPrice) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="showMode" label="可见模式" min-width="160">
                    <template slot-scope="{ row }">
                        <div class="a-flex-rfsc point-edit">
                            <span :class="row.showMode_edit?'a-c-red':'a-c-blue'">{{ row.showMode | initDic(showModelDic) }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="cardPrice" label="关联活动" min-width="200">
                    <template slot-scope="{ row }">
                        <div class="a-flex-rfsfs">
                            <div class="a-flex-rfsc a-flex-wrap" v-for="(item,index) in row.activityList" :key="item.id">
                                <span class="stationTag">
                                    {{ item.activityTitle?item.activityTitle:'-' }}
                                    {{ scope.row.activity.startTime?scope.row.activity.startTime:'-' }} 至 
                                    {{ scope.row.activity.endTime?scope.row.activity.endTime:'-' }}
                                    <i class="el-icon-error a-fs-14" @click="removeActivity(index)"></i>
                                </span>
                            </div>
                            <i class="el-icon-circle-plus-outline a-fs-24 a-c-red" @click="chooseActivity(row.index)"></i>
                        </div>
                        
                    </template>
                </el-table-column>
                <el-table-column prop="enableTimeText" label="开售/失效时间" min-width="420">
                    <template slot-scope="{ row }">
                        <jd-datetime-range 
                            :large="true" 
                            style="margin:-20px;padding: 0;" 
                            label="" 
                            valueFormat="timestamp" 
                            :minDate.sync="row.enableTime" 
                            :maxDate.sync="row.expireTime" />
                    </template>
                </el-table-column>
                <el-table-column prop="payWay" label="支付方式" min-width="160">
                    <template slot-scope="{ row }">
                        <div class="a-flex-rfsc point-edit" >
                            <span :class="row.payWay_edit?'a-c-red':'a-c-blue'">{{ row.payWay | initDic(payWayDic) }}</span>
                            <span :class="row.payWay_edit?'a-c-red':'a-c-blue'" style="margin: 0 10px" v-if="row.payWay == 3">，费用上限</span>
                            <span :class="row.payWay_edit?'a-c-red':'a-c-blue'" v-if="row.payWay == 3">￥{{ util.numFormat(row.maxPrice) }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="chargingExpireType" label="资源类型" min-width="160">
                    <template slot-scope="{ row }">
                        <div class="a-flex-rfsc point-edit" >
                            <span :class="row.chargingExpireType_edit?'a-c-red':'a-c-blue'">{{ row.chargingExpireType | initDic(chargingExpireTypeDic) }}</span>
                        </div>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="enableTimeText" label="开售时间" min-width="220">
                    <template slot-scope="{ row }">
                        <el-date-picker
                            v-model="row.enableTime"
                            valueFormat="timestamp"
                            type="datetime"
                            placeholder="选择日期时间">
                        </el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column prop="expireTimeText" label="失效时间" min-width="220">
                    <template slot-scope="{ row }">
                        <el-date-picker
                            v-model="row.expireTime"
                            valueFormat="timestamp"
                            type="datetime"
                            placeholder="选择日期时间">
                        </el-date-picker>
                    </template>
                </el-table-column> -->
                <el-table-column 
                    prop="isTempCard" 
                    label="卡种类" 
                    min-width="120" 
                    column-key="isTempCard"
                    :filter-multiple='false'
                    :filters="[]">
                    <template slot-scope="{ row }">
                        <span>{{ row.isTempCard | initDic(isTempcardDic) }}</span>
                    </template>
                </el-table-column>
                <el-table-column 
                    prop="cardType" 
                    label="卡类型" 
                    min-width="120" 
                    column-key="cardType"
                    :filter-multiple='false'
                    :filters="[]">
                    <template slot-scope="{ row }">
                        <span>{{ row.type | initDic(cardTypeDic) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="bikeTypeIdList" label="车辆" min-width="140">
                    <template slot-scope="{ row }">
                        <div class="a-flex-cfsfs">
                            <div v-for="(item,index) in row.bikeTypeIdList" :key="index">
                                {{ item.parentName?(item.parentName + '-'):'' + item.name }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                
                <el-table-column prop="promotionDescription" label="促销描述" min-width="200">
                    <template slot-scope="{ row }">
                        <span class="point-edit" :class="row.promotionDescription_edit?'a-c-red':'a-c-blue'">{{ row.promotionDescription?row.promotionDescription:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="applicableDescription" label="适用范围描述" min-width="200">
                    <template slot-scope="{ row }">
                        <span class="point-edit" :class="row.applicableDescription_edit?'a-c-red':'a-c-blue'">{{ row.applicableDescription?row.applicableDescription:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" prop="isCreate" label="是否创建" width="75">
                    <template slot-scope="{ row }">
                        <el-switch
                            v-model="row.isCreate"
                            active-color="#13ce66"
                            inactive-color="#ff4949">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" prop="status" label="状态" width="90">
                    <template slot-scope="{ row }">
                        <span v-if="row.status == 1" style="color: #67C23A"><i class="el-icon-success a-pr-4"></i>成功</span>
                        <span v-else-if="row.status == 2" style="color: #F56C6C"><i class="el-icon-error a-pr-4"></i>失败</span>
                        <span v-else style="color: #E6A23C"><i class="el-icon-question a-pr-4"></i>待生成</span>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height:17px"></div>
        </el-card>
        <div class="scrollBarDiv">
            <scroll-bar :table-ref="$refs.monthCardbatchlist"></scroll-bar>
        </div>
        <edit-table-value-dialog ref="editDialog" @submitVal="submitVal"></edit-table-value-dialog>
        <edit-table-payway-dialog ref="editPayWayDialog" @submitVal="submitPayWay"></edit-table-payway-dialog>
        <le-activity-choose :multiple="false" ref="chooseActivity" :defaultData="defaultActivity" @selectChange="getActivity"></le-activity-choose>
    </div>
</template>

<script>
import util from '../../../src/utils/util'
import scrollBar from '../components/table/scrollBar.vue';
import EditTableValueDialog from './child/editTableValue-dialog.vue';
import LeActivityChoose from '../components/choose/le-activity-choose.vue';
import dayjs from 'dayjs'
import EditTablePaywayDialog from './child/editTablePayway-dialog.vue';
    export default {
        components:{
            scrollBar,
            EditTableValueDialog,
            LeActivityChoose,
            EditTablePaywayDialog
        },
        data () {
            return {
                util: util,
                tableData: [],
                cosTableData: [],
                incomeDistriId: '',//当前项目id
                optionsincomeDistri: {
                    url: this.$Config.apiUrl.incomeDistriList, //getIncomeStatInfoList
                    method: "post",
                    params: {
                        searchKey: "",
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },//项目数据
                groupId: '',
                groupIdList: [],
                optionsTemplateList: {
                    url: this.$Config.apiUrl.monthCardTemplateList, //getIncomeStatInfoList
                    method: "post",
                    params: {
                        groupName: "",
                        type: 0,//0--套餐卡模版 1--储值卡模板
                    },
                    showLoading: false,
                    labelKey: "groupName",
                    valueKey: "groupId",
                    searchKey: "groupName",
                },//模板列表数据
                statioinIdList: [],// 当前选择站点id集合
                optionsStatioinIdList: {
                    url: this.$Config.apiUrl.monthStationList,
                    method: "post",
                    params: {
                        incomeDistriId: ''
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },//站点数据
                currentCell: null,//当前选中单元格
                cardTypeDic: [],//卡类型字典
                isTempcardDic: [],//是否临时卡字典
                isSystemDefaultDic: [],//是否系统默认卡字典
                activeCardDic: [],//激活模式字典
                needPostDic: [],//是否邮寄
                showModelDic: [],//可见模式字典
                cardGroupIds: [],
                payWayDic: [],
                chargingExpireTypeDic: [],
                defaultActivity: null,
                currentIndex: '',
                chargingExpireTypeNotice: true,
                iconDic: {
                    3: 'https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/wxapp/48v.png',
                    4: 'https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/wxapp/60v.png',
                    5: 'https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/wxapp/72v.png',
                },
                defaultIcon: 'https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/wxapp/month-list-icon3.0.png'
            }
        },
        watch:{
            incomeDistriId :{
                handler (val) {
                    this.optionsStatioinIdList.params.incomeDistriId = val
                    this.statioinIdList = []
                    // this.getCarGroup()
                }
            },
        },
        
        mounted () {
            this.$getDic('monthcardType').then(res=>{ this.cardTypeDic = res; })
            this.$getDic('istempcard').then(res=>{ this.isTempcardDic = res; })
            this.$getDic('isSystemDefault').then(res=>{ this.isSystemDefaultDic = res; })
            this.$getDic('activeCard').then(res=>{ this.activeCardDic = res; })
            this.$getDic('needPost').then(res=>{ this.needPostDic = res; })
            this.$getDic('showModel').then(res=>{ this.showModelDic = res; })
            this.$getDic('timeType').then(res=>{ this.timeTypeDic = res; }) 
            this.$getDic('payWay').then(res=>{ this.payWayDic = res; })
            this.$getDic('chargingExpireType').then(res=>{ this.chargingExpireTypeDic = res; })
            this.getGroupList()
        }, 
        methods: {
            getGroupList () {
                this.$Axios._post({
					url: this.$Config.apiUrl.monthCardTemplateList,
					params: {
						groupName: "",
                        type: 0,//0--套餐卡模版 1--储值卡模板
					}
				}).then(({
					data
				}) => {
					this.groupIdList = data.map(item=>{
                        return {
                            ...item,
                            label: item.groupName,
                            value: item.groupId
                        }
                    })
                    this.groupId = this.groupIdList[0].value
                    this.getTableData()
				})
            },
            handlerChangeGroupId (value) {
                this.groupId = value
                this.getTableData()
            },
            getCarGroup () {
                // 卡组不再前端负责添加生成
                // this.$Axios._post({
                //     url: this.$Config.apiUrl.getCardGroupList,
                //     method: "post",
                //     params: {
                //         incomedistriId: this.incomeDistriId,
                //         groupName: ''
                //     }
                // })
                // .then(res=>{
                //     if (res.result.code == 0) {
                //         this.cardGroupIds = res.result.data.map(item=>{
                //             return {
                //                 cardGroupId: item.id,
                //                 cardGroupName: item.groupName
                //             }
                //         })
                //     } else {
                //         this.$message.error(res.result.message)
                //     }
                // })
                // .catch(err=>{
                //     this.cardGroupIds = []
                // })
            },
            getTableData () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.monthCardTemplate,
                    params: {
                        groupId: this.groupId
                    },
                    method: "post",
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.tableData = res.result.data.map(item=>{
                            return {
                                ...JSON.parse(item.json),
                                // payWay: '1',
                                maxPrice: '',
                                enableTimeText: '',
                                enableTime: dayjs(),
                                expireTime: dayjs().add(20, 'year'),
                                expireTimeText: '',
                                id: null,
                                isCreate: true,
                                status: 0,
                                // chargingExpireType: '1',
                                showMode: '1',//默认全部可见
                                // icon: JSON.parse(item.json).bikeTypeIdList.length > 1 
                                //         ? this.defaultIcon
                                //         : this.iconDic[JSON.parse(item.json).bikeTypeIdList[0].code]
                                //             ? this.iconDic[JSON.parse(item.json).bikeTypeIdList[0].code]
                                //             : this.defaultIcon
                            }
                        })
                        this.getCosTableData()
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            },
            submit () {
                if(!this.statioinIdList.length){
                    this.$message.warning('请先选择项目站点')
                    return
                }
                this.$confirm('是否确认批量添加所选月卡套餐？','温馨提示')
                .then(async _=>{
                    let hasCreate = this.tableData.find(res=>{
                        return res.isCreate
                    })
                    if(!hasCreate) {
                        this.$message.warning('请至少勾选一条套餐模板')
                    }
                    let cardGroup = {}
                    this.tableData.map((item,index)=>{
                        if(!cardGroup[item.cardGroupId]) {
                            if(item.isCreate) {
                                this.$set(cardGroup,item.cardGroupId,new Array())
                                cardGroup[item.cardGroupId].push(item)
                            }
                        }else {
                            if(item.isCreate) {
                                cardGroup[item.cardGroupId].push(item)
                            }
                        }
                    })
                    for (let key in cardGroup) {
                        let item = cardGroup[key]
                        if(item && item.length) {
                            if(!item[0].enableTime || !item[0].expireTime){
                                this.$message.error('请填写‘'+item[0].name+'’的开售/失效时间')
                                break
                            }
                            await new Promise(resolve=>{
                                this.submitMonthCard([...item],(res)=>{
                                    if(res){
                                        item.map(it=>{
                                            it.isCreate = false
                                            it.status = 1
                                        })
                                    }else{
                                        item.map(it=>{
                                            it.isCreate = true
                                            it.status = 2
                                        })
                                    }
                                    resolve()
                                })
                            })
                        }
                    }
                    
                    
                })
                .catch(_=>{})
            },
            addCardGroup (item,callback) {
                let c_cardGroup = this.cardGroupIds.find(res=>{
                    return res.cardGroupName == item.cardGroupName
                })
                if(c_cardGroup){ // 当前卡组名称已存在
                    this.submitMonthCard({
                        ...item,
                        statioinIdList: this.statioinIdList,
                        incomeDistriId: this.incomeDistriId,
                        cardGroupId: c_cardGroup.cardGroupId,
                        cardGroupName: c_cardGroup.cardGroupName
                    },(res)=>{
                        if(res){
                            item.isCreate = false
                            item.status = 1
                        }else{
                            item.isCreate = true
                            item.status = 2
                        }
                        callback()
                    })
                }else{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.addCardGroup,
                        method: "post",
                        params: {
                            incomedistriId: this.incomeDistriId,
                            groupName: item.cardGroupName
                        }
                    }).then(res => {
                        if(res.result.code == 0){
                            this.cardGroupIds.push({
                                cardGroupId: res.result.data.id,
                                cardGroupName: res.result.data.groupName
                            })
                            this.submitMonthCard({
                                ...item,
                                statioinIdList: this.statioinIdList,
                                incomeDistriId: this.incomeDistriId,
                                cardGroupId: res.result.data.id,
                                cardGroupName: res.result.data.groupName
                            },(res)=>{
                                if(res){
                                    item.isCreate = false
                                    item.status = 1
                                }else{
                                    item.isCreate = true
                                    item.status = 2
                                }
                                callback()
                            })
                        }else{
                            this.$message.error(res.result.message)
                        }
                    }).catch(()=>{ })
                }
                
            },
            submitMonthCard (list,callback) {
                let reqDataArr = []
                list.map(item=>{
                    let reqData = {
                        ...item,
                        name: list[0].name,
                        cardPrice: list[0].cardPrice,
                        enableTime: list[0].enableTime,
                        expireTime: list[0].expireTime,
                        payWay: list[0].payWay,
                        chargingExpireType: list[0].chargingExpireType,
                        promotionDescription: list[0].promotionDescription,
                        applicableDescription: list[0].applicableDescription,
                        incomeDistriId: this.incomeDistriId,
                        cardGroupId: '',//将模板里面的卡组id清空，否则后端会认为这里是修改套餐
                    }
                    reqData.statioinIdList = this.statioinIdList.map(item=>{
                        return {
                            code: item,
                            name: ''
                        }
                    })
                    reqData.activityList = reqData.activity?[reqData.activity]:[]
                    reqDataArr.push(reqData)
                })
                this.$Axios._post({
                    url: this.$Config.apiUrl.addMonthCard,
                    method: "post",
                    params: {
                        validList: [...reqDataArr]
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.$message.success('操作成功')
                        callback(true)
                    } else {
                        this.$message.error(res.result.message)
                        callback(false)
                    }
                })
                .catch(err=>{
                    callback(false)
                })
            },
            // 给单元格绑定横向和竖向的index，这样就能确定是哪一个单元格
            tableCellClassName ({row, column, rowIndex, columnIndex}) {
                row.index=rowIndex;
                column.index=columnIndex;
            },
            rowStyle ({row, rowIndex}) {
                if(row.status == 1){
                    return {
                        'background': '#f0f9eb'
                    }
                }else if(row.status == 2){
                    return {
                        'background': 'rgb(254, 240, 240)'
                    }
                }else{
                    return {
                        'background': '#ffffff'
                    }
                }
            },
            // 获得当前双击的单元格的横竖index，然后拼接成一个唯一字符串用于判断，并赋给currentCell
            dbclick (row,column) {
                let black = ['enableTimeText','expireTimeText','isTempCard','cardType','bikeTypeIdList','isCreate','status']
                if(black.indexOf(column.property) != -1){
                    return
                }
                this.currentCell = {
                    rowIndex: row.index,
                    prop: column.property
                }
                
                if(column.property == 'promotionPrice'){
                    this.$refs['editDialog'].dialogVisible = true
                    let promotionPrice = row['promotionPrice']?row['promotionPrice']:0
                    let price = row['price']?row['price']:0
                    this.$refs['editDialog'].value1 = Number((promotionPrice/100).toFixed(2)) 
                    this.$refs['editDialog'].value2 = Number((price/100).toFixed(2))
                    this.$refs['editDialog'].label1 = '促销价'
                    this.$refs['editDialog'].label2 = '划线价'
                    this.$refs['editDialog'].title = '修改促销价/划线价'
                    this.$refs['editDialog'].inputType = 'money'
                }else if(column.property == 'cardPrice'){
                    this.$refs['editDialog'].dialogVisible = true
                    let cardPrice = row['cardPrice']?row['cardPrice']:0
                    this.$refs['editDialog'].value1 = Number((cardPrice/100).toFixed(2)) 
                    this.$refs['editDialog'].value2 = ''
                    this.$refs['editDialog'].label1 = column.label
                    this.$refs['editDialog'].label2 = ''
                    this.$refs['editDialog'].title = '修改'+column.label
                    this.$refs['editDialog'].inputType = 'money'
                }else if(column.property == 'payWay'){
                    this.$refs['editPayWayDialog'].dialogVisible = true
                    let maxPrice = row['maxPrice']?row['maxPrice']:0
                    let payWay = row['payWay']?row['payWay']:'1'
                    this.$refs['editPayWayDialog'].form.maxPrice = Number((maxPrice/100).toFixed(2)) 
                    this.$refs['editPayWayDialog'].form.payWay = payWay
                }else if(column.property == 'chargingExpireType'){
                    this.$refs['editDialog'].dialogVisible = true
                    this.$refs['editDialog'].value1 = row[column.property]
                    this.$refs['editDialog'].value2 = ''
                    this.$refs['editDialog'].valueType1 = 'radio'
                    this.$refs['editDialog'].valueDic1 = this.chargingExpireTypeDic
                    this.$refs['editDialog'].label1 = column.label
                    this.$refs['editDialog'].label2 = ''
                    this.$refs['editDialog'].title = '修改'+column.label
                    this.$refs['editDialog'].inputType = 'string'
                }else if(column.property == 'showMode'){
                    this.$refs['editDialog'].dialogVisible = true
                    this.$refs['editDialog'].value1 = row[column.property]
                    this.$refs['editDialog'].value2 = ''
                    this.$refs['editDialog'].valueType1 = 'select'
                    this.$refs['editDialog'].valueDic1 = this.showModelDic
                    this.$refs['editDialog'].label1 = column.label
                    this.$refs['editDialog'].label2 = ''
                    this.$refs['editDialog'].title = '修改'+column.label
                    this.$refs['editDialog'].inputType = 'string'
                }else{
                    this.$refs['editDialog'].dialogVisible = true
                    this.$refs['editDialog'].value1 = row[column.property]
                    this.$refs['editDialog'].value2 = ''
                    this.$refs['editDialog'].label1 = column.label
                    this.$refs['editDialog'].label2 = ''
                    this.$refs['editDialog'].title = '修改'+column.label
                    this.$refs['editDialog'].inputType = 'string'
                }
            },
            submitVal (datas) {
                if(this.currentCell.prop == 'promotionPrice'){
                    this.tableData[this.currentCell.rowIndex]['promotionPrice'] = datas.value1
                    this.tableData[this.currentCell.rowIndex]['price'] = datas.value2
                    this.$set(this.tableData[this.currentCell.rowIndex],'promotionPrice'+'_edit',true)

                }else if(this.currentCell.prop == 'chargingExpireType'){
                    if(this.chargingExpireTypeNotice){
                        this.$alert('资源类型修改后，对应卡组下的所有套餐的资源类型都会被改为对应类型，请仔细核对后提交。', '温馨提示', {
                            confirmButtonText: '我知道了',
                            showCancelButton: true,
                            cancelButtonText: '不再提示',
                            type: 'warning',
                            callback: action => {
                                if(action == 'cancel') {
                                    this.chargingExpireTypeNotice = false
                                }
                                this.tableData[this.currentCell.rowIndex][this.currentCell.prop] = datas.value1
                                this.$set(this.tableData[this.currentCell.rowIndex],this.currentCell.prop+'_edit',true)
                            }
                        });
                    }else{
                        this.tableData[this.currentCell.rowIndex][this.currentCell.prop] = datas.value1
                        this.$set(this.tableData[this.currentCell.rowIndex],this.currentCell.prop+'_edit',true)
                    }
                    
                }else{
                    this.tableData[this.currentCell.rowIndex][this.currentCell.prop] = datas.value1
                    this.$set(this.tableData[this.currentCell.rowIndex],this.currentCell.prop+'_edit',true)
                }
            },
            submitPayWay (datas) {
                this.tableData[this.currentCell.rowIndex]['payWay'] = datas.payWay
                this.tableData[this.currentCell.rowIndex]['maxPrice'] = datas.maxPrice
                this.$set(this.tableData[this.currentCell.rowIndex],this.currentCell.prop+'_edit',true)
            },
            // 当input失去焦点的时候，隐藏input
            hideInput () {
                this.currentCell = null;
            },
            // 选择添加活动
            chooseActivity (index) {
                this.defaultActivity = this.tableData[index].activityList?this.tableData[index].activityList[0]:null
                this.$refs['chooseActivity'].dialogVisible = true
                this.currentIndex = index
            },
            getActivity (datas) {
                // this.$nextTick(()=>{
                //     this.$set(this.form,'activity',datas)
                // })
                this.$set(this.tableData[this.currentIndex],'activityList',[])
                this.$set(this.tableData[this.currentIndex].activityList,0,datas)
                console.log(this.tableData);
            },
            // 删除选择的活动
            removeActivity (index) {
                this.$confirm('是否确认删除？','温馨提示')
                .then(_=>{
                    this.tableData[this.currentIndex].activityList.splice(index,1)
                })
                .catch(_=>{})
            },
            // 合并行
            mergeCols ({ row, column, rowIndex, columnIndex }) {
                if(columnIndex !== 1 && columnIndex !== 2 && columnIndex !== 4 && columnIndex !== 5 && columnIndex !== 9 && columnIndex !== 14 && columnIndex !== 15){
                    for (let index = 0; index < this.cosTableData.length; index++) {
                        const element = this.cosTableData[index];
                        for (let c_index = 0; c_index < element.length; c_index++) {
                            if(rowIndex == element[c_index]){
                                if(c_index == 0){
                                    return {
                                        rowspan: element.length,
                                        colspan: 1
                                    }
                                }else{
                                    return {
                                        rowspan: 0,
                                        colspan: 0
                                    }
                                }   
                            }
                        }
                    }
                }
            },
            // 获取需要合并单元格的数据
            getCosTableData () {
                this.cosTableData = []
                let obj = {}
                // 将相同编号的数据储存到同一个数组中，并以该编号为key值添加到obj对象中 以便之后取用
                this.tableData.map((item,index)=>{
                    if(obj[item.cardGroupId]){
                        obj[item.cardGroupId].push(index)
                    }else{
                        obj[item.cardGroupId] = []
                        obj[item.cardGroupId].push(index)
                    }
                })
                // 将obj对象中数组长度大于1（即存在多个相同编号）的数据push到this.cosTableData中
                for (const key in obj) {
                    if(obj[key].length > 1)
                    this.cosTableData.push(obj[key])
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.monthCard{
    .s-btn-add{
        width: 105px;
    }
    /deep/ .el-table__expand-column .cell {
        // display: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .stationTag{
        padding: 0px 6px;
        background: #f4f4f5;
        color: #909399;
        border: 1px solid #e9e9eb;
        border-radius: 3px;
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 12px;
        word-break: keep-all;
    }
    .my-btn {
        padding: 0 22px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #C4C8CC;
        position: relative;
        margin-right: 24px;
        cursor: pointer;
    }

    .my-btn-active {
        border: 1px solid #007AFF;
    }
}
/deep/ .sceneCheckContent{
    .el-table__column-filter-trigger{
        display: none;
    }
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
    margin-right: -18px;
}
.point-edit{
    // cursor: url('../../assets/icon/edit-mouse.png'), default;
    cursor: pointer;
}
.scrollBarDiv{
    width: auto;
    position: fixed;
    left: 157px;
    right: 0;
    bottom: 0;
    background: #fff;
    padding: 10px 52px 16px;
    z-index: 2000;
    border-top: 1px solid #EBEEF5;
}
.a-pr-4{
    padding-right: 4px;
}
.el-table .wait-row {
    background: #ffffff;
}

.el-table .success-row {
    background: #f0f9eb;
}
.el-table .fail-row {
    background: rgb(253, 226, 226);
}
</style>