<template>
    <div>
        <el-dialog
            title="选择活动"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            top="2vh"
            style="margin-bottom:1vh"
            width="1200px">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-select-local-search label="活动类型" v-model="pageParam.params.activityRewardsType" :options="activityRewardsTypeDic" />
                <le-input label="活动名称" v-model="pageParam.params.activityTitle" />
            </le-search-form>
            <le-pagview ref="activityChoose" @setData="setTableData" :pageParam="pageParam">
                <div class="a-flex-rfsfs" style="align-items: stretch;">
                    <el-table 
                        ref="chooseTable"
                        :data="tableData" 
                        :row-key="getRowKey"
                        @selection-change="handleSelectionChange"
                        @current-change="handleCurrentChange"
                        :highlight-current-row="true" 
                        height="55vh"
                        style="width: 100%">
                        <el-table-column type="selection" fixed="left" align="center" width="45" reserve-selection v-if="multiple"></el-table-column>
                        <el-table-column fixed="left" align="center" width="45" v-else>
                            <template slot-scope="{ row }">
                                <el-radio v-model="selectData" :label="row">&nbsp;</el-radio>
                            </template>
                        </el-table-column>
                        <el-table-column prop="id" label="ID" min-width="80"></el-table-column>
                        <el-table-column prop="activityTitle" label="名称" min-width="120"></el-table-column>
                        <el-table-column 
                            label="状态"
                            min-width="120">
                            <template slot-scope="{ row }">
                                <span>{{ row.status | initDic(statusStatusDic) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="incomeDistriId" label="内容" min-width="120">
                            <template slot-scope="{ row }">
                                <span>{{ row.activityRewardsType | initDic(activityRewardsTypeDic) }}：</span>
                                <span v-if="row.activityRewardsType==0">{{ row.activityRewardsValue?row.activityRewardsValue:'-' }}天</span>
                                <span v-if="row.activityRewardsType==1">{{ row.activityRewardsValue?Number((row.activityRewardsValue/100).toFixed(2)):'-' }}元</span>
                                <span v-if="row.activityRewardsType==2">{{ row.activityRewardsValue?row.activityRewardsValue:'-' }}折</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-card class="box-card a-ml-20">
                        <div slot="header" class="clearfix">
                            <span>已选择表格数据：</span>
                            <el-form :model="form" ref="form" :rules="rules" label-width="0" >
                                <el-form-item label="" prop='startTime'>
                                    <jd-datetime-range 
                                        style="margin: 10px 0 0 -20px;padding: 0;" 
                                        label="" 
                                        large
                                        :defaultTime="['00:00:00','23:59:59']"
                                        valueFormat="yyyy-MM-dd HH:mm:ss"
                                        :minDate.sync="form.startTime" 
                                        :maxDate.sync="form.endTime" />
                                </el-form-item>
                            </el-form>
                        </div>
                        <div v-if="multiple" class="chooseList">
                            <div class="a-flex-rsbc" v-for="(item,index) in selectData" :key="item.id" style="margin-bottom: 18px;">
                                <span>{{ item.activityTitle }}</span>
                                <i class="el-icon-error a-fs-14 a-ml-5 a-c-999" @click="removeChoose(item)"></i>
                            </div>
                            <div class="a-flex-rcc a-c-999" v-if="!selectData || !selectData.length">暂无数据</div>
                        </div>
                        <div v-else class="chooseList">
                            <div class="a-flex-rsbc" v-if="selectData" style="margin-bottom: 18px;">
                                <span>{{ selectData.activityTitle }}</span>
                                <i class="el-icon-error a-fs-14 a-ml-5 a-c-999" @click="removeChoose()"></i>
                            </div>
                            <div class="a-flex-rcc a-c-999" v-else>暂无数据</div>
                        </div>
                    </el-card>
                </div>
                
            </le-pagview>
            
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false;">取消</el-button>
                <el-button type="primary"  @click="submit">确定选择</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import util from '../../../utils/util'
    export default {
        data() {
            return {
                util: util,
                dialogVisible: false,
                tableData: [],
                selectData: null,//选中的列表
                pageParam: {
                    url: this.$Config.apiUrl.listActivity,
                    method: "post",
                    params: {
                        activityRewardsType: null, //活动奖励模式
                        activityTitle: null, //活动名称
                        activityType: '0'
                    },
                    freshCtrl: 1,
                },
                form: {
                    startTime: '',
                    endTime: '',    
                },
                rules: {
                    startTime: [{required: true, message:'请选择生效日期', trigger: 'change'}]
                },
                activityRewardsTypeDic: [],
                statusStatusDic: [],
            };
        },
        props:{
            defaultData:{
                default: null,
            },
            multiple:{
                default: false
            },
            editType: {
                default: ''
            }
        },
        watch:{
            dialogVisible (val) {
                if(val){
                    this.$nextTick(()=>{
                        this.handlerRest()
                        if(!this.multiple){
                            this.selectData = this.defaultData
                        }
                    })
                    
                }else{
                    this.$refs['chooseTable'].clearSelection()
                    this.$refs['chooseTable'].setCurrentRow()
                    this.selectData = null
                    this.$refs['form'].resetFields()
                }
            },
        },
        created () {
            this.$getDic('activityRewardsType','select').then(res=>{ this.activityRewardsTypeDic = res; })
            this.$getDic('activityStatus').then(res=>{ this.statusStatusDic = res; })
        },
        methods:{
            getRowKey (row) {
                return row.id
            },
            // 多选
            handleSelectionChange (selection) {
                this.selectData = selection
            },
            // 单选
            handleCurrentChange (row) {
                if(!this.multiple){
                    this.selectData = row
                }
            },
            // 设置表格选中状态
            toggleRowSelection (rows) {
                this.$nextTick(() => {
                    this.selectData.map((ite,idx)=>{
                        this.tableData.map((item, index) => {
                            if(ite.id == item.id){
                                this.$refs.chooseTable.toggleRowSelection(item, true);
                            }
                        });
                    })
                    
                }) 
            },
            handlerRest() {
                this.pageParam.params = {
                    activityRewardsType: null, //活动奖励模式
                    activityTitle: null, //活动名称
                    activityType: '0'
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['activityChoose'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            //获取列表
            setTableData(data) {
                this.tableData = data;
                if(this.multiple){
                    if(this.defaultData){
                        this.selectData = this.defaultData
                        this.toggleRowSelection()
                    }
                }else{
                    if(this.selectData){
                        let obj = this.tableData.find(res=>{
                            return res.id == this.selectData.id
                        })
                        this.selectData = obj || this.selectData
                    }
                }
            },
            submit () {
                if(this.multiple){
                    if(!this.selectData || !this.selectData.length){
                        this.$message.error('请至少选择一条表格数据')
                        return
                    }
                }else{
                    if(!this.selectData){
                        this.$message.error('请选择一条表格数据')
                        return
                    }
                }
                if(this.editType != 'addTemp') {
                    this.$refs['form'].validate((valid) => {
                        if (valid) {
                            if(this.multiple) {
                                this.selectData = this.selectData.map(item=>{
                                    return {
                                        ...item,
                                        startTime: this.form.startTime,
                                        endTime: this.form.endTime
                                    }
                                })
                            }else{
                                this.selectData.startTime = this.form.startTime
                                this.selectData.endTime = this.form.endTime
                            }
                            this.dialogVisible = false
                            this.$emit('selectChange',this.selectData)
                        } else {
                            this.$message.error('请选择生效日期')
                            return false
                        }
                    })
                }else {
                    this.dialogVisible = false
                    this.$emit('selectChange',this.selectData)
                }
                
            },
            removeChoose (datas) {
                this.$confirm('是否确认删除？','温馨提示')
                .then(_=>{
                    if(this.multiple){
                        this.$nextTick(()=>{
                            this.selectData.splice(this.selectData.indexOf(datas), 1);
                        })
                        this.$refs['chooseTable'].toggleRowSelection(datas)
                    }else{
                        this.selectData = null
                        this.$refs['chooseTable'].setCurrentRow()
                    }
                })
                .catch(_=>{})
                
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-dialog__body{
        padding: 0px 20px;
    }
    .stationTag{
        padding: 0px 6px;
        background: #f4f4f5;
        color: #909399;
        border: 1px solid #e9e9eb;
        border-radius: 3px;
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 12px;
        word-break: keep-all;
    }
    .chooseList{
        overflow:auto;
        width: 320px;
        height: calc(55vh - 200px);
    }
    .chooseList::-webkit-scrollbar {
        /*width: 0;宽度为0隐藏*/
        width: 0 !important;
        height: 0 !important;
    }
    .box-card{
        /deep/ .el-card__header {
            padding-bottom: 0 !important;
        }
    }
    
</style>